@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C600%3B0%2C700%3B0%2C900%3B1%2C300%3B1%2C400%3B1%2C600%3B1%2C700%3B1%2C900&family=Inter%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Poppins%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900%3B1%2C300%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700%3B1%2C900&family=Dela+Gothic+One%3Aital%2Cwght%400%2C400&display=swap");

.plasmic_placeit_plasmic_tokens__qBuFo {
  --token-TDUv9IlsPLe: #860ee6;
  --plasmic-token-primary-500: var(--token-TDUv9IlsPLe);
  --token-8j5e4W9rawI: #360485;
  --plasmic-token-primary-800: var(--token-8j5e4W9rawI);
  --token-JIfrimaWtNy: 32px;
  --plasmic-token-space-32: var(--token-JIfrimaWtNy);
  --token-_u5zxRyEmaF: 32px;
  --plasmic-token-heading-2: var(--token-_u5zxRyEmaF);
  --token-DckCCvtwain: 16px;
  --plasmic-token-text-l: var(--token-DckCCvtwain);
  --token-I-eWu6aAAvb: 40px;
  --plasmic-token-heading-1: var(--token-I-eWu6aAAvb);
  --token-XMoXRN_s63-: 20px;
  --plasmic-token-text-xxl: var(--token-XMoXRN_s63-);
  --token-5NIcBWGHbTC: 28px;
  --plasmic-token-heading-3: var(--token-5NIcBWGHbTC);
  --token-jFlfsKM8_wXg: 14px;
  --plasmic-token-text-m: var(--token-jFlfsKM8_wXg);
  --token-hOqCuytDszPG: 48px;
  --plasmic-token-display-1: var(--token-hOqCuytDszPG);
  --token-eX3zVaWtY: 56px;
  --plasmic-token-space-56: var(--token-eX3zVaWtY);
  --token-u8uQ-APga: 8px;
  --plasmic-token-space-8: var(--token-u8uQ-APga);
  --token-rYh-ACW-A: 4px;
  --plasmic-token-space-4: var(--token-rYh-ACW-A);
  --token-Szpdx-7Xp: #ae46f0;
  --plasmic-token-primary-400: var(--token-Szpdx-7Xp);
  --token-qaV8obD4f: 24px;
  --plasmic-token-heading-4: var(--token-qaV8obD4f);
  --token-AmaqypV2P: 80px;
  --plasmic-token-space-80: var(--token-AmaqypV2P);
  --token-hUFPDfh1_: #680ac5;
  --plasmic-token-primary-600: var(--token-hUFPDfh1_);
  --token-IXhP74seA: 48px;
  --plasmic-token-space-48: var(--token-IXhP74seA);
  --token-c-29j4nMe: 1536px;
  --plasmic-token-max-width-1536: var(--token-c-29j4nMe);
  --token-cTiaoKBgu: 24px;
  --plasmic-token-space-24: var(--token-cTiaoKBgu);
  --token-5BJzuQphn: #0e1c3d;
  --plasmic-token-cold-gray-900: var(--token-5BJzuQphn);
  --token-h1zUTQpAs: #182c53;
  --plasmic-token-cold-gray-800: var(--token-h1zUTQpAs);
  --token-HvtrWkdZP: #314d74;
  --plasmic-token-cold-gray-700: var(--token-HvtrWkdZP);
  --token-PLOt1qDGr: #5a78a0;
  --plasmic-token-cold-gray-600: var(--token-PLOt1qDGr);
  --token-W8LXJxZWP: #9fb2cc;
  --plasmic-token-cold-gray-500: var(--token-W8LXJxZWP);
  --token-4idf28fIO: #b6c5da;
  --plasmic-token-cold-gray-400: var(--token-4idf28fIO);
  --token-_Gt2dyZrU: #ccd8e8;
  --plasmic-token-cold-gray-300: var(--token-_Gt2dyZrU);
  --token-wrFJtVAO5: #e8edf4;
  --plasmic-token-cold-gray-200: var(--token-wrFJtVAO5);
  --token-D1V5iINMo: #ffffff;
  --plasmic-token-white: var(--token-D1V5iINMo);
  --token-Xm52tS8KN: #df95f3;
  --plasmic-token-primary-100: var(--token-Xm52tS8KN);
  --token-702EZImih: #f6f7fa;
  --plasmic-token-cold-gray-100: var(--token-702EZImih);
  --token-KSYikVdQL: #000000;
  --plasmic-token-gray-900: var(--token-KSYikVdQL);
  --token-aLKgcASGV: #303030;
  --plasmic-token-gray-800: var(--token-aLKgcASGV);
  --token-SV05R2W4q: #4a4a4b;
  --plasmic-token-gray-700: var(--token-SV05R2W4q);
  --token-H3oDifb0f: #626364;
  --plasmic-token-gray-600: var(--token-H3oDifb0f);
  --token-zni4LJ5Jc: #7b7c7d;
  --plasmic-token-gray-500: var(--token-zni4LJ5Jc);
  --token-DRJ-SVn25: #99999a;
  --plasmic-token-gray-400: var(--token-DRJ-SVn25);
  --token-S4o2LuwaP: #b6b7b8;
  --plasmic-token-gray-300: var(--token-S4o2LuwaP);
  --token-h_9wJQLam: #d4d4d5;
  --plasmic-token-gray-200: var(--token-h_9wJQLam);
  --token-rCP0JaPgY: #f1f1f2;
  --plasmic-token-gray-100: var(--token-rCP0JaPgY);
  --token-0ZyY30VTY: #000000;
  --plasmic-token-black: var(--token-0ZyY30VTY);
  --token-1T1xhlQ_M: #26026e;
  --plasmic-token-primary-900: var(--token-1T1xhlQ_M);
  --token-JJVT-lW_c: #4d07a5;
  --plasmic-token-primary-700: var(--token-JJVT-lW_c);
  --token-eHmIO-VN4: #c86cf7;
  --plasmic-token-primary-300: var(--token-eHmIO-VN4);
  --token-JxH9FWrnH: #e19dfc;
  --plasmic-token-primary-200: var(--token-JxH9FWrnH);
  --token-h0R5oMv1G: #cdf0ff;
  --plasmic-token-secondary-100: var(--token-h0R5oMv1G);
  --token-wRop-CguK: #9bdbff;
  --plasmic-token-secondary-200: var(--token-wRop-CguK);
  --token-lWQ4UzZI4: #69c3ff;
  --plasmic-token-secondary-300: var(--token-lWQ4UzZI4);
  --token-NmgA1xuhG: #43aaff;
  --plasmic-token-secondary-400: var(--token-NmgA1xuhG);
  --token-2FOTd83j-: #0582ff;
  --plasmic-token-secondary-500: var(--token-2FOTd83j-);
  --token-N2nhUC7Dn: #0364db;
  --plasmic-token-secondary-600: var(--token-N2nhUC7Dn);
  --token-LaavOb7bd: #024ab7;
  --plasmic-token-secondary-700: var(--token-LaavOb7bd);
  --token-TyN0A3ode: #013493;
  --plasmic-token-secondary-800: var(--token-TyN0A3ode);
  --token--WJnDmCUq: #00257a;
  --plasmic-token-secondary-900: var(--token--WJnDmCUq);
  --token-CeRZjJUng: #0b2b77;
  --plasmic-token-system-info-900: var(--token-CeRZjJUng);
  --token-XZ5KfWQ7_: #123e90;
  --plasmic-token-system-info-800: var(--token-XZ5KfWQ7_);
  --token-DqsJSWKw6: #1d59b3;
  --plasmic-token-system-info-700: var(--token-DqsJSWKw6);
  --token-egZEY8ekW: #2b78d6;
  --plasmic-token-system-info-600: var(--token-egZEY8ekW);
  --token-aC8Mkn4jn: #3b9af9;
  --plasmic-token-system-info-500: var(--token-aC8Mkn4jn);
  --token-0L2C3jJ78: #6bbafb;
  --plasmic-token-system-info-400: var(--token-0L2C3jJ78);
  --token-VCojihI40: #6bbafb;
  --plasmic-token-system-info-300: var(--token-VCojihI40);
  --token-Jtm2-Qct7: #b0e3fe;
  --plasmic-token-system-info-200: var(--token-Jtm2-Qct7);
  --token-mxi6ZWmsz: #d7f3fe;
  --plasmic-token-system-info-100: var(--token-mxi6ZWmsz);
  --token-elFM0RHgy: #1d5604;
  --plasmic-token-system-success-900: var(--token-elFM0RHgy);
  --token-RbZMeOM3G: #296807;
  --plasmic-token-system-success-800: var(--token-RbZMeOM3G);
  --token-Xu4qQzG9U: #3b820b;
  --plasmic-token-system-success-700: var(--token-Xu4qQzG9U);
  --token-8ScdpH5Uk: #4f9b10;
  --plasmic-token-system-success-600: var(--token-8ScdpH5Uk);
  --token-FEfuyUmf6: #4f9b10;
  --plasmic-token-system-success-500: var(--token-FEfuyUmf6);
  --token-OaUfVtTia: #94d248;
  --plasmic-token-system-success-400: var(--token-OaUfVtTia);
  --token-80NS5WhAh: #b8e86e;
  --plasmic-token-system-success-300: var(--token-80NS5WhAh);
  --token-vrVJPfGWj: #d9f7a1;
  --plasmic-token-system-success-200: var(--token-vrVJPfGWj);
  --token-wW24Km0x5: #eefbcf;
  --plasmic-token-system-success-100: var(--token-wW24Km0x5);
  --token-zUWALB7zW: #7a5400;
  --plasmic-token-system-info-warning-900: var(--token-zUWALB7zW);
  --token-rmcREdi3F: #936901;
  --plasmic-token-system-info-warning-800: var(--token-rmcREdi3F);
  --token-hlre08zp7: #b78702;
  --plasmic-token-system-info-warning-700: var(--token-hlre08zp7);
  --token-ee2qkrJG7: #dba603;
  --plasmic-token-system-info-warning-600: var(--token-ee2qkrJG7);
  --token-QCoSZP2jy: #ffc805;
  --plasmic-token-system-info-warning-500: var(--token-QCoSZP2jy);
  --token-ya8lJLadQ: #ffc805;
  --plasmic-token-system-info-warning-400: var(--token-ya8lJLadQ);
  --token-NQvfaN4KQ: #ffe469;
  --plasmic-token-system-info-warning-300: var(--token-NQvfaN4KQ);
  --token-52olfxhXZ: #ffef9b;
  --plasmic-token-system-info-warning-200: var(--token-52olfxhXZ);
  --token-mmSW3_hl0: #fff8cd;
  --plasmic-token-system-info-warning-100: var(--token-mmSW3_hl0);
  --token-d29encmkK: #770a38;
  --plasmic-token-system-info-danger-900: var(--token-d29encmkK);
  --token-_I4xY-NzS: #90113c;
  --plasmic-token-system-info-danger-800: var(--token-_I4xY-NzS);
  --token-XWksz6y5Z: #b31b40;
  --plasmic-token-system-info-danger-700: var(--token-XWksz6y5Z);
  --token-8Ihx9g_J8: #d62742;
  --plasmic-token-system-info-danger-600: var(--token-8Ihx9g_J8);
  --token-YWKVPlpcR: #f93643;
  --plasmic-token-system-info-danger-500: var(--token-YWKVPlpcR);
  --token-_UWFjAUye: #fb6b67;
  --plasmic-token-system-info-danger-400: var(--token-_UWFjAUye);
  --token-4vzGEfE4m: #fd9386;
  --plasmic-token-system-info-danger-300: var(--token-4vzGEfE4m);
  --token-Z1rNUxyCc: #febeae;
  --plasmic-token-system-info-danger-200: var(--token-Z1rNUxyCc);
  --token-hnWjT-5TQ: #febeae;
  --plasmic-token-system-info-danger-100: var(--token-hnWjT-5TQ);
  --token-TCakqYrfy: 16px;
  --plasmic-token-space-16: var(--token-TCakqYrfy);
  --token-omGhEM8OI: 40px;
  --plasmic-token-space-40: var(--token-omGhEM8OI);
  --token-k8o5cN9Xy: 64px;
  --plasmic-token-space-64: var(--token-k8o5cN9Xy);
  --token-MCc8jZqax: 112px;
  --plasmic-token-space-104: var(--token-MCc8jZqax);
  --token-Q7mOfPk_X: 72px;
  --plasmic-token-space-72: var(--token-Q7mOfPk_X);
  --token-mTH4m9NT7: 88px;
  --plasmic-token-space-88: var(--token-mTH4m9NT7);
  --token-jD4-AUsFo: 96px;
  --plasmic-token-space-96: var(--token-jD4-AUsFo);
  --token-INTG9yOs7: 120px;
  --plasmic-token-space-120: var(--token-INTG9yOs7);
  --token-wSGdpGsDD: 40px;
  --plasmic-token-display-2: var(--token-wSGdpGsDD);
  --token-7vjXi0uPm: 32px;
  --plasmic-token-display-3: var(--token-7vjXi0uPm);
  --token-OVQ_pKinX: 28px;
  --plasmic-token-display-4: var(--token-OVQ_pKinX);
  --token-_MOw8VJgR: 24px;
  --plasmic-token-display-5: var(--token-_MOw8VJgR);
  --token-WkKdYF02Z: 20px;
  --plasmic-token-heading-5: var(--token-WkKdYF02Z);
  --token-fQy-x1YLR: 18px;
  --plasmic-token-subtitle-1: var(--token-fQy-x1YLR);
  --token-q7WiRBt0u: 16px;
  --plasmic-token-subtitle-2: var(--token-q7WiRBt0u);
  --token-MXg62A1zO: 14px;
  --plasmic-token-subtitle-3: var(--token-MXg62A1zO);
  --token-ecA0KrA9h: 12px;
  --plasmic-token-subtitle-4: var(--token-ecA0KrA9h);
  --token-p_i3YrSH0: 24px;
  --plasmic-token-text-xxxl: var(--token-p_i3YrSH0);
  --token--qjKwCUH1: 18px;
  --plasmic-token-text-xl: var(--token--qjKwCUH1);
  --token-jc2d5nONS: 12px;
  --plasmic-token-text-s: var(--token-jc2d5nONS);
  --token-ufBEFU4Jv: 10px;
  --plasmic-token-text-xs: var(--token-ufBEFU4Jv);
  --token-PsTTGOOvU: Poppins;
  --plasmic-token-display: var(--token-PsTTGOOvU);
  --token-x6dXhym8c: Source Sans Pro;
  --plasmic-token-headings: var(--token-x6dXhym8c);
  --token-Hn0tANBmC: Source Sans Pro;
  --plasmic-token-text: var(--token-Hn0tANBmC);
  --token-wmteqkiSA: 1.3;
  --plasmic-token-display-height: var(--token-wmteqkiSA);
  --token-MHxx4_Rh4: 1.5;
  --plasmic-token-text-height: var(--token-MHxx4_Rh4);
  --token-KdEawj5Kt: -160px;
  --plasmic-token-top-space-for-anchors: var(--token-KdEawj5Kt);
  --token-o261nSUZXz66: #74d8d3;
  --plasmic-token-printful-ce: var(--token-o261nSUZXz66);
  --token-AYb-7S01YPbu: var(--token-KSYikVdQL);
  --plasmic-token-unnamed-style-token: var(--token-AYb-7S01YPbu);
  --token-QdXW9EvdRBHg: #74d8d3;
  --plasmic-token-unnamed-style-token-2: var(--token-QdXW9EvdRBHg);
  --token-pH6hIQoYFDVF: #ba3d37;
  --plasmic-token-unnamed-style-token-3: var(--token-pH6hIQoYFDVF);
  --token-zdLUr_-wJu0A: #ffffff;
  --plasmic-token-unnamed-style-token-4: var(--token-zdLUr_-wJu0A);
  --token-6qbhvTgkLKn5: #fb393c;
  --plasmic-token-cyber-week-2024: var(--token-6qbhvTgkLKn5);
  --token-boGrFwFO91mu: #baf701b3;
  --plasmic-token-cyber-week-yellow: var(--token-boGrFwFO91mu);
  --token-n8gPkDkRSRlg: #f2ff00b3;
  --plasmic-token-cw-yellow-right: var(--token-n8gPkDkRSRlg);
  --token-PVSKgbZylV9Q: #860ee6;
  --plasmic-token-womens-day-2025: var(--token-PVSKgbZylV9Q);
  --token-TSgy6DyhAI60: #46296e;
  --plasmic-token-wd-2025: var(--token-TSgy6DyhAI60);
}

@media (min-width: 1536px) {
  .plasmic_placeit_plasmic_tokens__qBuFo {
    --token-c-29j4nMe: 1536px;
    --plasmic-token-max-width-1536: var(--token-c-29j4nMe);
  }
}

.plasmic_placeit_plasmic_tokens__qBuFo:where(.plasmic_placeit_global_ctaLevel_monthly__t22ry) {
  --token-8j5e4W9rawI: #ba3d37;
  --plasmic-token-primary-800: var(--token-8j5e4W9rawI);
}

.plasmic_placeit_plasmic_tokens__qBuFo {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_placeit_plasmic_default_styles__CLwB7,
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_ctaLevel_monthly__t22ry),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_ctaLevel_annual__YZipj),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_ctaLevel_pastdue__AX4FB),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_ctaLevel_churned__d1DyK),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_ctaLevel_regular__jtZwQ),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_ctaLevel_visitor__HTmRX),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_testExpAbCd3Fg1_c2__Lq44D),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_testExpAbCd3Fg1_c3__7Egx2),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global__010119700000123129992359_override__JZaV_),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_region_geo1__N_vvW),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_region_geo2__lWz90),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_region_geo3__sv8ky),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_region_geo4___vS4e),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_region_geoin__TppX4),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_region_geobr__T_M0h),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global__102920241500110120240500_override__S1nzs),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global__112720241500120420240500_override__7Ka31),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global__121620241500122620240500_override__VnPEi),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global__122620240501010120250600_override__wS1xo),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global_c1RWdyOnVipExbhYAf__2N__0b4JE),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global__010920251500021020252050_override__xlLyn),
.plasmic_placeit_plasmic_default_styles__CLwB7:where(.plasmic_placeit_global__021020252100030720251600_override__Yv2S2) {
  --mixin-mS28rJ1xxePs_font-family: "Source Sans Pro", sans-serif;
  --mixin-mS28rJ1xxePs_font-size: 16px;
  --mixin-mS28rJ1xxePs_font-weight: 400;
  --mixin-mS28rJ1xxePs_font-style: normal;
  --mixin-mS28rJ1xxePs_color: var(--token-0ZyY30VTY);
  --mixin-mS28rJ1xxePs_text-align: left;
  --mixin-mS28rJ1xxePs_text-transform: none;
  --mixin-mS28rJ1xxePs_line-height: 1.5;
  --mixin-mS28rJ1xxePs_letter-spacing: normal;
  --mixin-mS28rJ1xxePs_white-space: pre-wrap;
  --mixin-mS28rJ1xxePs_user-select: text;
  --mixin-mS28rJ1xxePs_text-decoration-line: none;
  --mixin-mS28rJ1xxePs_text-overflow: clip;
  --mixin-JV3OnRimuuF-_color: #000000;
  --mixin-JV3OnRimuuF-_font-weight: 900;
  --mixin-JV3OnRimuuF-_font-size: 72px;
  --mixin-JV3OnRimuuF-_line-height: 1;
  --mixin-JV3OnRimuuF-_letter-spacing: -4px;
  --mixin-JV3OnRimuuF-_white-space: pre-wrap;
  --mixin-vDJwRWDRZv90_font-family: "Inter", sans-serif;
  --mixin-vDJwRWDRZv90_color: #000000;
  --mixin-vDJwRWDRZv90_font-size: 48px;
  --mixin-vDJwRWDRZv90_font-weight: 700;
  --mixin-vDJwRWDRZv90_letter-spacing: -1px;
  --mixin-vDJwRWDRZv90_line-height: 1.1;
  --mixin-vDJwRWDRZv90_white-space: pre-wrap;
  --mixin-wIXq9kmRQrKj_color: var(--token-jgohepLVeKvh);
  --mixin-wIXq9kmRQrKj_white-space: pre-wrap;
  --mixin-CJ-zDNK7x_ED_font-family: "Inter", sans-serif;
  --mixin-CJ-zDNK7x_ED_color: #000000;
  --mixin-CJ-zDNK7x_ED_font-size: 32px;
  --mixin-CJ-zDNK7x_ED_font-weight: 600;
  --mixin-CJ-zDNK7x_ED_letter-spacing: -0.8px;
  --mixin-CJ-zDNK7x_ED_line-height: 1.2;
  --mixin-CJ-zDNK7x_ED_white-space: pre-wrap;
  --mixin-ROMneKvNfC9B_font-family: "Inter", sans-serif;
  --mixin-ROMneKvNfC9B_color: #000000;
  --mixin-ROMneKvNfC9B_font-size: 24px;
  --mixin-ROMneKvNfC9B_font-weight: 600;
  --mixin-ROMneKvNfC9B_letter-spacing: -0.5px;
  --mixin-ROMneKvNfC9B_line-height: 1.3;
  --mixin-ROMneKvNfC9B_white-space: pre-wrap;
  --mixin-ctO5Zwow2n0s_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-ctO5Zwow2n0s_border-bottom-color: #dddddd;
  --mixin-ctO5Zwow2n0s_border-bottom-style: solid;
  --mixin-ctO5Zwow2n0s_border-bottom-width: 1px;
  --mixin-ctO5Zwow2n0s_border-left-color: #dddddd;
  --mixin-ctO5Zwow2n0s_border-left-style: solid;
  --mixin-ctO5Zwow2n0s_border-left-width: 1px;
  --mixin-ctO5Zwow2n0s_border-right-color: #dddddd;
  --mixin-ctO5Zwow2n0s_border-right-style: solid;
  --mixin-ctO5Zwow2n0s_border-right-width: 1px;
  --mixin-ctO5Zwow2n0s_border-top-color: #dddddd;
  --mixin-ctO5Zwow2n0s_border-top-style: solid;
  --mixin-ctO5Zwow2n0s_border-top-width: 1px;
  --mixin-ctO5Zwow2n0s_border-bottom-left-radius: 3px;
  --mixin-ctO5Zwow2n0s_border-bottom-right-radius: 3px;
  --mixin-ctO5Zwow2n0s_border-top-left-radius: 3px;
  --mixin-ctO5Zwow2n0s_border-top-right-radius: 3px;
  --mixin-ctO5Zwow2n0s_font-family: "Inconsolata";
  --mixin-ctO5Zwow2n0s_padding-bottom: 1px;
  --mixin-ctO5Zwow2n0s_padding-left: 4px;
  --mixin-ctO5Zwow2n0s_padding-right: 4px;
  --mixin-ctO5Zwow2n0s_padding-top: 1px;
  --mixin-ctO5Zwow2n0s_white-space: pre-wrap;
  --mixin-0fQtSapPbI5K_border-left-color: #dddddd;
  --mixin-0fQtSapPbI5K_border-left-style: solid;
  --mixin-0fQtSapPbI5K_border-left-width: 3px;
  --mixin-0fQtSapPbI5K_color: #888888;
  --mixin-0fQtSapPbI5K_padding-left: 10px;
  --mixin-0fQtSapPbI5K_white-space: pre-wrap;
  --mixin-k-RtPX24xbWq_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-k-RtPX24xbWq_border-bottom-color: #dddddd;
  --mixin-k-RtPX24xbWq_border-bottom-style: solid;
  --mixin-k-RtPX24xbWq_border-bottom-width: 1px;
  --mixin-k-RtPX24xbWq_border-left-color: #dddddd;
  --mixin-k-RtPX24xbWq_border-left-style: solid;
  --mixin-k-RtPX24xbWq_border-left-width: 1px;
  --mixin-k-RtPX24xbWq_border-right-color: #dddddd;
  --mixin-k-RtPX24xbWq_border-right-style: solid;
  --mixin-k-RtPX24xbWq_border-right-width: 1px;
  --mixin-k-RtPX24xbWq_border-top-color: #dddddd;
  --mixin-k-RtPX24xbWq_border-top-style: solid;
  --mixin-k-RtPX24xbWq_border-top-width: 1px;
  --mixin-k-RtPX24xbWq_border-bottom-left-radius: 3px;
  --mixin-k-RtPX24xbWq_border-bottom-right-radius: 3px;
  --mixin-k-RtPX24xbWq_border-top-left-radius: 3px;
  --mixin-k-RtPX24xbWq_border-top-right-radius: 3px;
  --mixin-k-RtPX24xbWq_font-family: "Inconsolata";
  --mixin-k-RtPX24xbWq_padding-bottom: 3px;
  --mixin-k-RtPX24xbWq_padding-left: 6px;
  --mixin-k-RtPX24xbWq_padding-right: 6px;
  --mixin-k-RtPX24xbWq_padding-top: 3px;
  --mixin-k-RtPX24xbWq_white-space: pre-wrap;
  --mixin-10MWiXPevtGO_display: flex;
  --mixin-10MWiXPevtGO_flex-direction: column;
  --mixin-10MWiXPevtGO_align-items: stretch;
  --mixin-10MWiXPevtGO_justify-content: flex-start;
  --mixin-10MWiXPevtGO_list-style-position: outside;
  --mixin-10MWiXPevtGO_padding-left: 40px;
  --mixin-10MWiXPevtGO_position: relative;
  --mixin-10MWiXPevtGO_list-style-type: disc;
  --mixin-10MWiXPevtGO_white-space: pre-wrap;
  --mixin-_Sb4E8VPbr3n_display: flex;
  --mixin-_Sb4E8VPbr3n_flex-direction: column;
  --mixin-_Sb4E8VPbr3n_align-items: stretch;
  --mixin-_Sb4E8VPbr3n_justify-content: flex-start;
  --mixin-_Sb4E8VPbr3n_list-style-position: outside;
  --mixin-_Sb4E8VPbr3n_padding-left: 40px;
  --mixin-_Sb4E8VPbr3n_position: relative;
  --mixin-_Sb4E8VPbr3n_list-style-type: decimal;
  --mixin-_Sb4E8VPbr3n_white-space: pre-wrap;
  --mixin-g8EjmzTtiDe1j_font-family: "Inter", sans-serif;
  --mixin-g8EjmzTtiDe1j_color: #000000;
  --mixin-g8EjmzTtiDe1j_font-size: 20px;
  --mixin-g8EjmzTtiDe1j_font-weight: 600;
  --mixin-g8EjmzTtiDe1j_letter-spacing: -0.3px;
  --mixin-g8EjmzTtiDe1j_line-height: 1.5;
  --mixin-g8EjmzTtiDe1j_white-space: pre-wrap;
  --mixin-Tg6_4vqBpr9np_font-family: "Inter", sans-serif;
  --mixin-Tg6_4vqBpr9np_color: #000000;
  --mixin-Tg6_4vqBpr9np_font-size: 16px;
  --mixin-Tg6_4vqBpr9np_font-weight: 600;
  --mixin-Tg6_4vqBpr9np_line-height: 1.5;
  --mixin-Tg6_4vqBpr9np_white-space: pre-wrap;
  --mixin-cEIMeHoWgLX2y_color: #3291ff;
  --mixin-cEIMeHoWgLX2y_white-space: pre-wrap;
}

.plasmic_placeit_plasmic_mixins__37xcP,
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_ctaLevel_monthly__t22ry),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_ctaLevel_annual__YZipj),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_ctaLevel_pastdue__AX4FB),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_ctaLevel_churned__d1DyK),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_ctaLevel_regular__jtZwQ),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_ctaLevel_visitor__HTmRX),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_testExpAbCd3Fg1_c2__Lq44D),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_testExpAbCd3Fg1_c3__7Egx2),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global__010119700000123129992359_override__JZaV_),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_region_geo1__N_vvW),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_region_geo2__lWz90),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_region_geo3__sv8ky),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_region_geo4___vS4e),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_region_geoin__TppX4),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_region_geobr__T_M0h),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global__102920241500110120240500_override__S1nzs),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global__112720241500120420240500_override__7Ka31),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global__121620241500122620240500_override__VnPEi),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global__122620240501010120250600_override__wS1xo),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global_c1RWdyOnVipExbhYAf__2N__0b4JE),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global__010920251500021020252050_override__xlLyn),
.plasmic_placeit_plasmic_mixins__37xcP:where(.plasmic_placeit_global__021020252100030720251600_override__Yv2S2) {
  --mixin-NEzn3lh7Sq_white-space: pre-wrap;
  --plasmic-mixin-display-1_white-space: var(--mixin-NEzn3lh7Sq_white-space);
  --mixin-gWjWme9GlaC_box-shadow: 0px 0px 0px 3px #007aff80;
  --plasmic-mixin-focus-shadow_box-shadow: var(--mixin-gWjWme9GlaC_box-shadow);
  --mixin-gWjWme9GlaC_white-space: pre-wrap;
  --plasmic-mixin-focus-shadow_white-space: var(
    --mixin-gWjWme9GlaC_white-space
  );
  --mixin-_10zCo0LR_white-space: pre-wrap;
  --plasmic-mixin-display-2_white-space: var(--mixin-_10zCo0LR_white-space);
  --mixin-cdluE4bqP_white-space: pre-wrap;
  --plasmic-mixin-display-3_white-space: var(--mixin-cdluE4bqP_white-space);
  --mixin-5mUzIc4qI_white-space: pre-wrap;
  --plasmic-mixin-display-4_white-space: var(--mixin-5mUzIc4qI_white-space);
  --mixin-Cvm4NcNt9_white-space: pre-wrap;
  --plasmic-mixin-display-5_white-space: var(--mixin-Cvm4NcNt9_white-space);
  --mixin-atPLhs1Wu_white-space: pre-wrap;
  --plasmic-mixin-heading-1_white-space: var(--mixin-atPLhs1Wu_white-space);
  --mixin-s7muV2qmN_white-space: pre-wrap;
  --plasmic-mixin-heading-2_white-space: var(--mixin-s7muV2qmN_white-space);
  --mixin-_cL2djS9r_white-space: pre-wrap;
  --plasmic-mixin-heading-3_white-space: var(--mixin-_cL2djS9r_white-space);
  --mixin-gVhza2pG9_white-space: pre-wrap;
  --plasmic-mixin-heading-4_white-space: var(--mixin-gVhza2pG9_white-space);
  --mixin-M444NAfDv_white-space: pre-wrap;
  --plasmic-mixin-heading-5_white-space: var(--mixin-M444NAfDv_white-space);
  --mixin-cQm4XjXDE_white-space: pre-wrap;
  --plasmic-mixin-subtitle-1_white-space: var(--mixin-cQm4XjXDE_white-space);
  --mixin-ApgkwjmhR_white-space: pre-wrap;
  --plasmic-mixin-subtitle-2_white-space: var(--mixin-ApgkwjmhR_white-space);
  --mixin-q-9je0ZVs_white-space: pre-wrap;
  --plasmic-mixin-subtitle-3_white-space: var(--mixin-q-9je0ZVs_white-space);
  --mixin-VFd0r_RLQ_white-space: pre-wrap;
  --plasmic-mixin-subtitle-4_white-space: var(--mixin-VFd0r_RLQ_white-space);
  --mixin-ltgN9T9-7_white-space: pre-wrap;
  --plasmic-mixin-text-xxxl-regular_white-space: var(
    --mixin-ltgN9T9-7_white-space
  );
  --mixin-ms5GdHKIY_white-space: pre-wrap;
  --plasmic-mixin-text-xxxl-semi_white-space: var(
    --mixin-ms5GdHKIY_white-space
  );
  --mixin-3i2Aeian3_white-space: pre-wrap;
  --plasmic-mixin-text-xxl-regular_white-space: var(
    --mixin-3i2Aeian3_white-space
  );
  --mixin-ZsRZWD_rR_white-space: pre-wrap;
  --plasmic-mixin-text-xxl-semi_white-space: var(--mixin-ZsRZWD_rR_white-space);
  --mixin-NlUo16pT1_white-space: pre-wrap;
  --plasmic-mixin-text-xl-regular_white-space: var(
    --mixin-NlUo16pT1_white-space
  );
  --mixin-5eeJTt3Z1_white-space: pre-wrap;
  --plasmic-mixin-text-xl-semi_white-space: var(--mixin-5eeJTt3Z1_white-space);
  --mixin-pGJlNI3nZ_white-space: pre-wrap;
  --plasmic-mixin-text-xl-bold_white-space: var(--mixin-pGJlNI3nZ_white-space);
  --mixin-kk2lXUltj_white-space: pre-wrap;
  --plasmic-mixin-text-l-regular_white-space: var(
    --mixin-kk2lXUltj_white-space
  );
  --mixin-SEX4mdoE-_white-space: pre-wrap;
  --plasmic-mixin-text-l-semi_white-space: var(--mixin-SEX4mdoE-_white-space);
  --mixin-qRo3Rtwie_white-space: pre-wrap;
  --plasmic-mixin-text-l-bold_white-space: var(--mixin-qRo3Rtwie_white-space);
  --mixin-wsQA8eOWh_white-space: pre-wrap;
  --plasmic-mixin-text-m-regular_white-space: var(
    --mixin-wsQA8eOWh_white-space
  );
  --mixin-zVhOaBVXt_white-space: pre-wrap;
  --plasmic-mixin-text-m-semi_white-space: var(--mixin-zVhOaBVXt_white-space);
  --mixin-iORIGf2Zz_white-space: pre-wrap;
  --plasmic-mixin-text-m-bold_white-space: var(--mixin-iORIGf2Zz_white-space);
  --mixin-W2Q5_YGbw_white-space: pre-wrap;
  --plasmic-mixin-text-s-regular_white-space: var(
    --mixin-W2Q5_YGbw_white-space
  );
  --mixin-GeQiyuyrR_white-space: pre-wrap;
  --plasmic-mixin-text-s-semi_white-space: var(--mixin-GeQiyuyrR_white-space);
  --mixin-ua31O02MK_white-space: pre-wrap;
  --plasmic-mixin-text-s-bold_white-space: var(--mixin-ua31O02MK_white-space);
  --mixin-bB-25Gkvk_white-space: pre-wrap;
  --plasmic-mixin-text-xs-regular_white-space: var(
    --mixin-bB-25Gkvk_white-space
  );
  --mixin-FF4LcAxmV_white-space: pre-wrap;
  --plasmic-mixin-text-xs-semi_white-space: var(--mixin-FF4LcAxmV_white-space);
  --mixin-HzXx-Thes_white-space: pre-wrap;
  --plasmic-mixin-text-xs-bold_white-space: var(--mixin-HzXx-Thes_white-space);
}

:where(.plasmic_placeit_all__iHKzr) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  margin: 0;
  border-width: 0px;
}

:where(.plasmic_placeit_img__JJ6ik) {
  display: inline-block;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY img) {
  white-space: inherit;
}

:where(.plasmic_placeit_li__3UjFN) {
  display: list-item;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY li) {
  white-space: inherit;
}

:where(.plasmic_placeit_span__hy2mZ) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_placeit_input__de03G) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_placeit_textarea__Kuq_t) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_placeit_button__Euyo4) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.plasmic_placeit_code__gfIbx) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_placeit_pre__WVUkm) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_placeit_p__bkXXf) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_placeit_h1__mv4s_) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_placeit_h2__OuDhX) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_placeit_h3__H1MAA) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_placeit_h4__332n2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_placeit_h5__W9yZ1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_placeit_h6__p2WIU) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_placeit_address__10Zq_) {
  font-style: inherit;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.plasmic_placeit_a__WwOnR) {
  color: inherit;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY a) {
  white-space: inherit;
  color: inherit;
}

:where(.plasmic_placeit_ol__2JT4D) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_placeit_ul__TIdVg) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_placeit_select__9P6oj) {
  padding: 2px 6px;
}
:where(.plasmic_placeit___wab_expr_html_text__1srcY select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_placeit_plasmic_default__component_wrapper__O06Rm {
  display: grid;
}
.plasmic_placeit_plasmic_default__inline__cdPuW {
  display: inline;
}
.plasmic_placeit_plasmic_page_wrapper__iA9TY {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_placeit_plasmic_page_wrapper__iA9TY > * {
  height: auto !important;
}
.plasmic_placeit___wab_expr_html_text__1srcY {
  white-space: normal;
}
:where(.plasmic_placeit_root_reset__PJXZH) {
  font-family: var(--mixin-mS28rJ1xxePs_font-family);
  font-size: var(--mixin-mS28rJ1xxePs_font-size);
  font-weight: var(--mixin-mS28rJ1xxePs_font-weight);
  font-style: var(--mixin-mS28rJ1xxePs_font-style);
  color: var(--mixin-mS28rJ1xxePs_color);
  text-align: var(--mixin-mS28rJ1xxePs_text-align);
  text-transform: var(--mixin-mS28rJ1xxePs_text-transform);
  line-height: var(--mixin-mS28rJ1xxePs_line-height);
  letter-spacing: var(--mixin-mS28rJ1xxePs_letter-spacing);
  white-space: var(--mixin-mS28rJ1xxePs_white-space);
}

:where(.plasmic_placeit_root_reset__PJXZH) h1:where(.plasmic_placeit_h1__mv4s_),
h1:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_h1__mv4s_),
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) h1,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) h1,
h1:where(.plasmic_placeit_root_reset_tags__Zq2s9) {
  color: var(--mixin-JV3OnRimuuF-_color);
  font-weight: var(--mixin-JV3OnRimuuF-_font-weight);
  font-size: var(--mixin-JV3OnRimuuF-_font-size);
  line-height: var(--mixin-JV3OnRimuuF-_line-height);
  letter-spacing: var(--mixin-JV3OnRimuuF-_letter-spacing);
}

:where(.plasmic_placeit_root_reset__PJXZH) h2:where(.plasmic_placeit_h2__OuDhX),
h2:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_h2__OuDhX),
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) h2,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) h2,
h2:where(.plasmic_placeit_root_reset_tags__Zq2s9) {
  font-family: var(--mixin-vDJwRWDRZv90_font-family);
  color: var(--mixin-vDJwRWDRZv90_color);
  font-size: var(--mixin-vDJwRWDRZv90_font-size);
  font-weight: var(--mixin-vDJwRWDRZv90_font-weight);
  letter-spacing: var(--mixin-vDJwRWDRZv90_letter-spacing);
  line-height: var(--mixin-vDJwRWDRZv90_line-height);
}

:where(.plasmic_placeit_root_reset__PJXZH) a:where(.plasmic_placeit_a__WwOnR),
a:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_a__WwOnR),
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) a,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) a,
a:where(.plasmic_placeit_root_reset_tags__Zq2s9) {
  color: var(--mixin-wIXq9kmRQrKj_color);
}

:where(.plasmic_placeit_root_reset__PJXZH) h3:where(.plasmic_placeit_h3__H1MAA),
h3:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_h3__H1MAA),
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) h3,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) h3,
h3:where(.plasmic_placeit_root_reset_tags__Zq2s9) {
  font-family: var(--mixin-CJ-zDNK7x_ED_font-family);
  color: var(--mixin-CJ-zDNK7x_ED_color);
  font-size: var(--mixin-CJ-zDNK7x_ED_font-size);
  font-weight: var(--mixin-CJ-zDNK7x_ED_font-weight);
  letter-spacing: var(--mixin-CJ-zDNK7x_ED_letter-spacing);
  line-height: var(--mixin-CJ-zDNK7x_ED_line-height);
}

:where(.plasmic_placeit_root_reset__PJXZH) h4:where(.plasmic_placeit_h4__332n2),
h4:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_h4__332n2),
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) h4,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) h4,
h4:where(.plasmic_placeit_root_reset_tags__Zq2s9) {
  font-family: var(--mixin-ROMneKvNfC9B_font-family);
  color: var(--mixin-ROMneKvNfC9B_color);
  font-size: var(--mixin-ROMneKvNfC9B_font-size);
  font-weight: var(--mixin-ROMneKvNfC9B_font-weight);
  letter-spacing: var(--mixin-ROMneKvNfC9B_letter-spacing);
  line-height: var(--mixin-ROMneKvNfC9B_line-height);
}

:where(.plasmic_placeit_root_reset__PJXZH) code:where(.plasmic_placeit_code__gfIbx),
code:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_code__gfIbx),
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) code,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) code,
code:where(.plasmic_placeit_root_reset_tags__Zq2s9) {
  background: #f8f8f8;
  font-family: var(--mixin-ctO5Zwow2n0s_font-family);
  border-radius: var(--mixin-ctO5Zwow2n0s_border-top-left-radius)
    var(--mixin-ctO5Zwow2n0s_border-top-right-radius)
    var(--mixin-ctO5Zwow2n0s_border-bottom-right-radius)
    var(--mixin-ctO5Zwow2n0s_border-bottom-left-radius);
  padding: var(--mixin-ctO5Zwow2n0s_padding-top)
    var(--mixin-ctO5Zwow2n0s_padding-right)
    var(--mixin-ctO5Zwow2n0s_padding-bottom)
    var(--mixin-ctO5Zwow2n0s_padding-left);
  border-top: var(--mixin-ctO5Zwow2n0s_border-top-width)
    var(--mixin-ctO5Zwow2n0s_border-top-style)
    var(--mixin-ctO5Zwow2n0s_border-top-color);
  border-right: var(--mixin-ctO5Zwow2n0s_border-right-width)
    var(--mixin-ctO5Zwow2n0s_border-right-style)
    var(--mixin-ctO5Zwow2n0s_border-right-color);
  border-bottom: var(--mixin-ctO5Zwow2n0s_border-bottom-width)
    var(--mixin-ctO5Zwow2n0s_border-bottom-style)
    var(--mixin-ctO5Zwow2n0s_border-bottom-color);
  border-left: var(--mixin-ctO5Zwow2n0s_border-left-width)
    var(--mixin-ctO5Zwow2n0s_border-left-style)
    var(--mixin-ctO5Zwow2n0s_border-left-color);
}

:where(.plasmic_placeit_root_reset__PJXZH) blockquote:where(.plasmic_placeit_blockquote__3jr8c),
blockquote:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_blockquote__3jr8c),
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) blockquote,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) blockquote,
blockquote:where(.plasmic_placeit_root_reset_tags__Zq2s9) {
  color: var(--mixin-0fQtSapPbI5K_color);
  padding-left: var(--mixin-0fQtSapPbI5K_padding-left);
  border-left: var(--mixin-0fQtSapPbI5K_border-left-width)
    var(--mixin-0fQtSapPbI5K_border-left-style)
    var(--mixin-0fQtSapPbI5K_border-left-color);
}

:where(.plasmic_placeit_root_reset__PJXZH) pre:where(.plasmic_placeit_pre__WVUkm),
pre:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_pre__WVUkm),
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) pre,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) pre,
pre:where(.plasmic_placeit_root_reset_tags__Zq2s9) {
  background: #f8f8f8;
  font-family: var(--mixin-k-RtPX24xbWq_font-family);
  border-radius: var(--mixin-k-RtPX24xbWq_border-top-left-radius)
    var(--mixin-k-RtPX24xbWq_border-top-right-radius)
    var(--mixin-k-RtPX24xbWq_border-bottom-right-radius)
    var(--mixin-k-RtPX24xbWq_border-bottom-left-radius);
  padding: var(--mixin-k-RtPX24xbWq_padding-top)
    var(--mixin-k-RtPX24xbWq_padding-right)
    var(--mixin-k-RtPX24xbWq_padding-bottom)
    var(--mixin-k-RtPX24xbWq_padding-left);
  border-top: var(--mixin-k-RtPX24xbWq_border-top-width)
    var(--mixin-k-RtPX24xbWq_border-top-style)
    var(--mixin-k-RtPX24xbWq_border-top-color);
  border-right: var(--mixin-k-RtPX24xbWq_border-right-width)
    var(--mixin-k-RtPX24xbWq_border-right-style)
    var(--mixin-k-RtPX24xbWq_border-right-color);
  border-bottom: var(--mixin-k-RtPX24xbWq_border-bottom-width)
    var(--mixin-k-RtPX24xbWq_border-bottom-style)
    var(--mixin-k-RtPX24xbWq_border-bottom-color);
  border-left: var(--mixin-k-RtPX24xbWq_border-left-width)
    var(--mixin-k-RtPX24xbWq_border-left-style)
    var(--mixin-k-RtPX24xbWq_border-left-color);
}

:where(.plasmic_placeit_root_reset__PJXZH) ul:where(.plasmic_placeit_ul__TIdVg),
ul:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_ul__TIdVg),
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) ul,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) ul,
ul:where(.plasmic_placeit_root_reset_tags__Zq2s9) {
  display: var(--mixin-10MWiXPevtGO_display);
  flex-direction: var(--mixin-10MWiXPevtGO_flex-direction);
  align-items: var(--mixin-10MWiXPevtGO_align-items);
  justify-content: var(--mixin-10MWiXPevtGO_justify-content);
  list-style-position: var(--mixin-10MWiXPevtGO_list-style-position);
  padding-left: var(--mixin-10MWiXPevtGO_padding-left);
  position: var(--mixin-10MWiXPevtGO_position);
  list-style-type: var(--mixin-10MWiXPevtGO_list-style-type);
  flex-column-gap: var(--mixin-10MWiXPevtGO_flex-column-gap);
}

:where(.plasmic_placeit_root_reset__PJXZH) ol:where(.plasmic_placeit_ol__2JT4D),
ol:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_ol__2JT4D),
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) ol,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) ol,
ol:where(.plasmic_placeit_root_reset_tags__Zq2s9) {
  display: var(--mixin-_Sb4E8VPbr3n_display);
  flex-direction: var(--mixin-_Sb4E8VPbr3n_flex-direction);
  align-items: var(--mixin-_Sb4E8VPbr3n_align-items);
  justify-content: var(--mixin-_Sb4E8VPbr3n_justify-content);
  list-style-position: var(--mixin-_Sb4E8VPbr3n_list-style-position);
  padding-left: var(--mixin-_Sb4E8VPbr3n_padding-left);
  position: var(--mixin-_Sb4E8VPbr3n_position);
  list-style-type: var(--mixin-_Sb4E8VPbr3n_list-style-type);
  flex-column-gap: var(--mixin-_Sb4E8VPbr3n_flex-column-gap);
}

:where(.plasmic_placeit_root_reset__PJXZH) h5:where(.plasmic_placeit_h5__W9yZ1),
h5:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_h5__W9yZ1),
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) h5,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) h5,
h5:where(.plasmic_placeit_root_reset_tags__Zq2s9) {
  font-family: var(--mixin-g8EjmzTtiDe1j_font-family);
  color: var(--mixin-g8EjmzTtiDe1j_color);
  font-size: var(--mixin-g8EjmzTtiDe1j_font-size);
  font-weight: var(--mixin-g8EjmzTtiDe1j_font-weight);
  letter-spacing: var(--mixin-g8EjmzTtiDe1j_letter-spacing);
  line-height: var(--mixin-g8EjmzTtiDe1j_line-height);
}

:where(.plasmic_placeit_root_reset__PJXZH) h6:where(.plasmic_placeit_h6__p2WIU),
h6:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_h6__p2WIU),
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) h6,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) h6,
h6:where(.plasmic_placeit_root_reset_tags__Zq2s9) {
  font-family: var(--mixin-Tg6_4vqBpr9np_font-family);
  color: var(--mixin-Tg6_4vqBpr9np_color);
  font-size: var(--mixin-Tg6_4vqBpr9np_font-size);
  font-weight: var(--mixin-Tg6_4vqBpr9np_font-weight);
  line-height: var(--mixin-Tg6_4vqBpr9np_line-height);
}

:where(.plasmic_placeit_root_reset__PJXZH) a:where(.plasmic_placeit_a__WwOnR):hover,
a:where(.plasmic_placeit_root_reset__PJXZH.plasmic_placeit_a__WwOnR):hover,
:where(.plasmic_placeit_root_reset__PJXZH .plasmic_placeit___wab_expr_html_text__1srcY) a:hover,
:where(.plasmic_placeit_root_reset_tags__Zq2s9) a:hover,
a:where(.plasmic_placeit_root_reset_tags__Zq2s9):hover {
  color: var(--mixin-cEIMeHoWgLX2y_color);
}

.plasmic_antd_5_hostless_plasmic_tokens__Qxekp {
  --token-pRs3-4jrQ4XQ: var(--antd-colorPrimary);
  --plasmic-token-system-primary: var(--token-pRs3-4jrQ4XQ);
  --token-sn6Zb6km7CqW: var(--antd-colorSuccess);
  --plasmic-token-system-success: var(--token-sn6Zb6km7CqW);
  --token-47ovROB-Xr2C: var(--antd-colorWarning);
  --plasmic-token-system-warning: var(--token-47ovROB-Xr2C);
  --token--ypw6enySR1T: var(--antd-colorError);
  --plasmic-token-system-error: var(--token--ypw6enySR1T);
  --token-GEppKCdlxgWK: var(--antd-colorInfo);
  --plasmic-token-system-info: var(--token-GEppKCdlxgWK);
  --token-kzT08fQBAHgz: var(--antd-colorText);
  --plasmic-token-system-text: var(--token-kzT08fQBAHgz);
  --token-LqpCVPPZF4_r: var(--antd-colorTextSecondary);
  --plasmic-token-system-text-secondary: var(--token-LqpCVPPZF4_r);
  --token-RHb8DXPBtDBn: var(--antd-colorTextTertiary);
  --plasmic-token-system-text-tertiary: var(--token-RHb8DXPBtDBn);
  --token--rHJyFFIHSqx: var(--antd-colorTextQuaternary);
  --plasmic-token-system-text-quaternary: var(--token--rHJyFFIHSqx);
  --token-Pxe4wDL2kJpb: var(--antd-colorBorder);
  --plasmic-token-system-border: var(--token-Pxe4wDL2kJpb);
  --token-LN1YkB123lXQ: var(--antd-colorBorderSecondary);
  --plasmic-token-system-border-secondary: var(--token-LN1YkB123lXQ);
  --token-MjYUWCcX38Uk: var(--antd-colorFill);
  --plasmic-token-system-fill: var(--token-MjYUWCcX38Uk);
  --token-cjVB4i6JVY6v: var(--antd-colorFillSecondary);
  --plasmic-token-system-fill-secondary: var(--token-cjVB4i6JVY6v);
  --token-9ZrR1Kut5qQh: var(--antd-colorFillTertiary);
  --plasmic-token-system-fill-tertiary: var(--token-9ZrR1Kut5qQh);
  --token-5flkhAz6pTxg: var(--antd-colorFillQuaternary);
  --plasmic-token-system-fill-quaternary: var(--token-5flkhAz6pTxg);
  --token-DbGdjBpQ6R_X: var(--antd-colorBgLayout);
  --plasmic-token-system-bg-layout: var(--token-DbGdjBpQ6R_X);
  --token-jgohepLVeKvh: var(--antd-colorBgContainer);
  --plasmic-token-system-bg-container: var(--token-jgohepLVeKvh);
  --token-zy6xhYnWF8Y7: var(--antd-colorBgElevated);
  --plasmic-token-system-bg-elevated: var(--token-zy6xhYnWF8Y7);
  --token-RvPI6LyeIPmd: var(--antd-colorBgSpotlight);
  --plasmic-token-system-bg-spotlight: var(--token-RvPI6LyeIPmd);
  --token-6Lhw5mK6wolF: var(--antd-colorPrimaryBg);
  --plasmic-token-system-primary-bg: var(--token-6Lhw5mK6wolF);
  --token-6G9-A5vsJlrH: var(--antd-colorPrimaryBgHover);
  --plasmic-token-system-primary-bg-hover: var(--token-6G9-A5vsJlrH);
  --token-ukwKZMvHWx7C: var(--antd-colorPrimaryBorder);
  --plasmic-token-system-primary-border: var(--token-ukwKZMvHWx7C);
  --token-8lqFm4pa_96a: var(--antd-colorPrimaryBorderHover);
  --plasmic-token-system-primary-border-hover: var(--token-8lqFm4pa_96a);
  --token-0W4hE_aeKQkw: var(--antd-colorPrimaryHover);
  --plasmic-token-system-primary-hover: var(--token-0W4hE_aeKQkw);
  --token-zeOmhvqK1boB: var(--antd-colorPrimaryActive);
  --plasmic-token-system-primary-active: var(--token-zeOmhvqK1boB);
  --token-Y4FX9LFslfTe: var(--antd-colorPrimaryTextHover);
  --plasmic-token-system-primary-text-hover: var(--token-Y4FX9LFslfTe);
  --token-DxfYAkV7QVUJ: var(--antd-colorPrimaryText);
  --plasmic-token-system-primary-text: var(--token-DxfYAkV7QVUJ);
  --token-zXou5HA2hb1R: var(--antd-colorPrimaryTextActive);
  --plasmic-token-system-primary-text-active: var(--token-zXou5HA2hb1R);
  --token-TgVT8YipjX7B: var(--antd-colorSuccessBg);
  --plasmic-token-system-success-bg: var(--token-TgVT8YipjX7B);
  --token-R0SUfsNirUr0: var(--antd-colorSuccessBgHover);
  --plasmic-token-system-success-bg-hover: var(--token-R0SUfsNirUr0);
  --token-mIWA40Rvp-L3: var(--antd-colorSuccessBorder);
  --plasmic-token-system-success-border: var(--token-mIWA40Rvp-L3);
  --token-uumNIGVKomsI: var(--antd-colorSuccessBorderHover);
  --plasmic-token-system-success-border-hover: var(--token-uumNIGVKomsI);
  --token-Vb0i3yqgVWX8: var(--antd-colorSuccessHover);
  --plasmic-token-system-success-hover: var(--token-Vb0i3yqgVWX8);
  --token-JP-pI4-MAOWS: var(--antd-colorSuccessActive);
  --plasmic-token-system-success-active: var(--token-JP-pI4-MAOWS);
  --token-nJiUvSvijQl3: var(--antd-colorSuccessTextHover);
  --plasmic-token-system-success-text-hover: var(--token-nJiUvSvijQl3);
  --token-6u49iN2cxqzs: var(--antd-colorSuccessText);
  --plasmic-token-system-success-text: var(--token-6u49iN2cxqzs);
  --token-U2IgSncNyCpY: var(--antd-colorSuccessTextActive);
  --plasmic-token-system-success-text-active: var(--token-U2IgSncNyCpY);
  --token-KrBMMqlPClZi: var(--antd-colorWarningBg);
  --plasmic-token-system-warning-bg: var(--token-KrBMMqlPClZi);
  --token-AIwAzeyxiz1x: var(--antd-colorWarningBgHover);
  --plasmic-token-system-warning-bg-hover: var(--token-AIwAzeyxiz1x);
  --token-u21Gzl9DygXI: var(--antd-colorWarningBorder);
  --plasmic-token-system-warning-border: var(--token-u21Gzl9DygXI);
  --token-M_5fD3bsBbm4: var(--antd-colorWarningBorderHover);
  --plasmic-token-system-warning-border-hover: var(--token-M_5fD3bsBbm4);
  --token-IFDRLVnOGObf: var(--antd-colorWarningHover);
  --plasmic-token-system-warning-hover: var(--token-IFDRLVnOGObf);
  --token-Ycl7DlUSVY4S: var(--antd-colorWarningActive);
  --plasmic-token-system-warning-active: var(--token-Ycl7DlUSVY4S);
  --token-bHHJuMx2zyxz: var(--antd-colorWarningTextHover);
  --plasmic-token-system-warning-text-hover: var(--token-bHHJuMx2zyxz);
  --token-EueuB3d74YKA: var(--antd-colorWarningText);
  --plasmic-token-system-warning-text: var(--token-EueuB3d74YKA);
  --token-2ObYh10hZuCh: var(--antd-colorWarningTextActive);
  --plasmic-token-system-warning-text-active: var(--token-2ObYh10hZuCh);
  --token-pi_amP1jS-3_: var(--antd-colorInfoBg);
  --plasmic-token-system-info-bg: var(--token-pi_amP1jS-3_);
  --token-AkJsX7eWcpyM: var(--antd-colorInfoBgHover);
  --plasmic-token-system-info-bg-hover: var(--token-AkJsX7eWcpyM);
  --token-q9Q0revQu5u0: var(--antd-colorInfoBorder);
  --plasmic-token-system-info-border: var(--token-q9Q0revQu5u0);
  --token-Ynjs6ut2v3nV: var(--antd-colorInfoBorderHover);
  --plasmic-token-system-info-border-hover: var(--token-Ynjs6ut2v3nV);
  --token-Z9jDmLfGJ4ec: var(--antd-colorInfoHover);
  --plasmic-token-system-info-hover: var(--token-Z9jDmLfGJ4ec);
  --token-sD0iOLam3c1_: var(--antd-colorInfoActive);
  --plasmic-token-system-info-active: var(--token-sD0iOLam3c1_);
  --token-Z5g3UaQUYJKs: var(--antd-colorInfoTextHover);
  --plasmic-token-system-info-text-hover: var(--token-Z5g3UaQUYJKs);
  --token-_T1cyz1fvJ5i: var(--antd-colorInfoText);
  --plasmic-token-system-info-text: var(--token-_T1cyz1fvJ5i);
  --token-VY6DggwR9lvs: var(--antd-colorInfoTextActive);
  --plasmic-token-system-info-text-active: var(--token-VY6DggwR9lvs);
  --token-y67qtjHCZN4U: var(--antd-colorErrorBg);
  --plasmic-token-system-error-bg: var(--token-y67qtjHCZN4U);
  --token-0MrDlDLqtJFU: var(--antd-colorErrorBgHover);
  --plasmic-token-system-error-bg-hover: var(--token-0MrDlDLqtJFU);
  --token-a78v3Ul_J4it: var(--antd-colorErrorBorder);
  --plasmic-token-system-error-border: var(--token-a78v3Ul_J4it);
  --token-IbRLKagyRkT2: var(--antd-colorErrorBorderHover);
  --plasmic-token-system-error-border-hover: var(--token-IbRLKagyRkT2);
  --token-COF46JTyVgAT: var(--antd-colorErrorHover);
  --plasmic-token-system-error-hover: var(--token-COF46JTyVgAT);
  --token-Bo2_tT25lwh4: var(--antd-colorErrorActive);
  --plasmic-token-system-error-active: var(--token-Bo2_tT25lwh4);
  --token-nd9tWjNBRhuz: var(--antd-colorErrorTextHover);
  --plasmic-token-system-error-text-hover: var(--token-nd9tWjNBRhuz);
  --token-ZDcvRU4gUKFH: var(--antd-colorErrorText);
  --plasmic-token-system-error-text: var(--token-ZDcvRU4gUKFH);
  --token-8dHZNKgzL8aW: var(--antd-colorErrorTextActive);
  --plasmic-token-system-error-text-active: var(--token-8dHZNKgzL8aW);
  --token-Yq556PvPjGii: var(--antd-colorWhite);
  --plasmic-token-system-white: var(--token-Yq556PvPjGii);
  --token-Le07ngBUPl2h: var(--antd-colorBgMask);
  --plasmic-token-system-bg-mask: var(--token-Le07ngBUPl2h);
  --token-y50Rafqey4Ec: var(--antd-colorIcon);
  --plasmic-token-system-icon: var(--token-y50Rafqey4Ec);
  --token-0jflij2Drauk: var(--antd-colorIconHover);
  --plasmic-token-system-icon-hover: var(--token-0jflij2Drauk);
  --token-p9GbVc-07pR7: var(--antd-colorLink);
  --plasmic-token-system-link: var(--token-p9GbVc-07pR7);
  --token-CmNnmfJ0iNRo: var(--antd-colorLinkHover);
  --plasmic-token-system-link-hover: var(--token-CmNnmfJ0iNRo);
  --token-cSJ2BJk-9JCq: var(--antd-paddingXXS);
  --plasmic-token-system-padding-xxs: var(--token-cSJ2BJk-9JCq);
  --token-oYI7ebdZitcv: var(--antd-paddingXS);
  --plasmic-token-system-padding-xs: var(--token-oYI7ebdZitcv);
  --token-Tf_KtjkxGdsv: var(--antd-paddingSM);
  --plasmic-token-system-padding-sm: var(--token-Tf_KtjkxGdsv);
  --token-UEz8jpx4ZeEk: var(--antd-padding);
  --plasmic-token-system-padding-m: var(--token-UEz8jpx4ZeEk);
  --token-Kzvu_5ZcCKzb: var(--antd-paddingMD);
  --plasmic-token-system-padding-md: var(--token-Kzvu_5ZcCKzb);
  --token-02IfQLozsPKb: var(--antd-paddingLG);
  --plasmic-token-system-padding-lg: var(--token-02IfQLozsPKb);
  --token-oW80B2Q_Cufz: var(--antd-paddingXL);
  --plasmic-token-system-padding-xl: var(--token-oW80B2Q_Cufz);
  --token-mZAl1RAwSIao: var(--antd-marginXXS);
  --plasmic-token-system-margin-xxs: var(--token-mZAl1RAwSIao);
  --token-9EfSOc8y7ano: var(--antd-marginXS);
  --plasmic-token-system-margin-xs: var(--token-9EfSOc8y7ano);
  --token-2ZPCvclvmwq9: var(--antd-marginSM);
  --plasmic-token-system-margin-sm: var(--token-2ZPCvclvmwq9);
  --token-_m46Z7TakRfI: var(--antd-margin);
  --plasmic-token-system-margin-m: var(--token-_m46Z7TakRfI);
  --token-4njgnJV5KOkS: var(--antd-marginMD);
  --plasmic-token-system-margin-md: var(--token-4njgnJV5KOkS);
  --token-eRNxHqcEHlFY: var(--antd-marginLG);
  --plasmic-token-system-margin-lg: var(--token-eRNxHqcEHlFY);
  --token-_8m4SPxiIjg9: var(--antd-marginXL);
  --plasmic-token-system-margin-xl: var(--token-_8m4SPxiIjg9);
  --token-dT-owZu8-6an: var(--antd-marginXXL);
  --plasmic-token-system-margin-xxl: var(--token-dT-owZu8-6an);
  --token-7z5Kw5B89TZR: var(--antd-fontSize);
  --plasmic-token-system-m: var(--token-7z5Kw5B89TZR);
  --token-o0Nl0Hefncwa: var(--antd-fontSizeSM);
  --plasmic-token-system-sm: var(--token-o0Nl0Hefncwa);
  --token-hYndcje43OGL: var(--antd-fontSizeLG);
  --plasmic-token-system-lg: var(--token-hYndcje43OGL);
  --token-BwOcdtoxJOlu: var(--antd-fontSizeXL);
  --plasmic-token-system-xl: var(--token-BwOcdtoxJOlu);
  --token-EBGyLHrpfttb: var(--antd-fontSizeHeading1);
  --plasmic-token-system-heading-1: var(--token-EBGyLHrpfttb);
  --token-bucIARGmMoc6: var(--antd-fontSizeHeading2);
  --plasmic-token-system-heading-2: var(--token-bucIARGmMoc6);
  --token-UXPBeNJ4rrVx: var(--antd-fontSizeHeading3);
  --plasmic-token-system-heading-3: var(--token-UXPBeNJ4rrVx);
  --token-B10hUni6jzpI: var(--antd-fontSizeHeading4);
  --plasmic-token-system-heading-4: var(--token-B10hUni6jzpI);
  --token-BlA-iDkaAWvO: var(--antd-fontSizeHeading5);
  --plasmic-token-system-heading-5: var(--token-BlA-iDkaAWvO);
  --token-_K7eyous79ni: var(--antd-lineHeight);
  --plasmic-token-system-m: var(--token-_K7eyous79ni);
  --token-fklKQN0M3LP2: var(--antd-lineHeightLG);
  --plasmic-token-system-lg: var(--token-fklKQN0M3LP2);
  --token-wxMcxZBYnxHD: var(--antd-lineHeightSM);
  --plasmic-token-system-sm: var(--token-wxMcxZBYnxHD);
  --token-sYDi1zi84t3y: var(--antd-lineHeightHeading1);
  --plasmic-token-system-heading-1: var(--token-sYDi1zi84t3y);
  --token-OclCNN0pXGGm: var(--antd-lineHeightHeading2);
  --plasmic-token-system-heading-2: var(--token-OclCNN0pXGGm);
  --token-yxIE6w4M2bri: var(--antd-lineHeightHeading3);
  --plasmic-token-system-heading-3: var(--token-yxIE6w4M2bri);
  --token-OdRcIpB2LnUT: var(--antd-lineHeightHeading4);
  --plasmic-token-system-heading-4: var(--token-OdRcIpB2LnUT);
  --token-FixeSDxCoolc: var(--antd-lineHeightHeading5);
  --plasmic-token-system-heading-5: var(--token-FixeSDxCoolc);
}

:where(.plasmic_antd_5_hostless_all__tOr6n) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.plasmic_antd_5_hostless_img__SzrGT) {
  display: inline-block;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc img) {
  white-space: inherit;
}

:where(.plasmic_antd_5_hostless_li__1opDU) {
  display: list-item;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc li) {
  white-space: inherit;
}

:where(.plasmic_antd_5_hostless_span__XwXr_) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_antd_5_hostless_input__vzP5V) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_antd_5_hostless_textarea__nOZzG) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_antd_5_hostless_button__4C4mw) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.plasmic_antd_5_hostless_code__uK1SC) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_antd_5_hostless_pre__4uvHm) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_antd_5_hostless_p__Getvc) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_antd_5_hostless_h1__SziON) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_antd_5_hostless_h2__IJDQk) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_antd_5_hostless_h3__VQyBv) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_antd_5_hostless_h4__9WSgP) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_antd_5_hostless_h5__VPERD) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_antd_5_hostless_h6__82MUR) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_antd_5_hostless_address__mAtLL) {
  font-style: inherit;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.plasmic_antd_5_hostless_a__FGbe5) {
  color: inherit;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc a) {
  white-space: inherit;
  color: inherit;
}

:where(.plasmic_antd_5_hostless_ol__cedoi) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_antd_5_hostless_ul__c9Txe) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_antd_5_hostless_select__3leGc) {
  padding: 2px 6px;
}
:where(.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_antd_5_hostless_plasmic_default__component_wrapper__1vBy_ {
  display: grid;
}
.plasmic_antd_5_hostless_plasmic_default__inline__1jwaB {
  display: inline;
}
.plasmic_antd_5_hostless_plasmic_page_wrapper__yUL5C {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_antd_5_hostless_plasmic_page_wrapper__yUL5C > * {
  height: auto !important;
}
.plasmic_antd_5_hostless___wab_expr_html_text__bi3nc {
  white-space: normal;
}
:where(.plasmic_antd_5_hostless_root_reset__SH6Uu) {
}

.plasmic_plasmic_rich_components_plasmic_tokens__NakKt {
  --token-iWQmILTzm3N0: var(--antd-colorPrimary);
  --plasmic-token-system-primary: var(--token-iWQmILTzm3N0);
  --token-HyiizzCtnTod: var(--antd-colorSuccess);
  --plasmic-token-system-success: var(--token-HyiizzCtnTod);
  --token-DUPkVtvXeJJo: var(--antd-colorWarning);
  --plasmic-token-system-warning: var(--token-DUPkVtvXeJJo);
  --token-fDNuFs9Skt3p: var(--antd-colorError);
  --plasmic-token-system-error: var(--token-fDNuFs9Skt3p);
  --token-CtWQF2P39aLo: var(--antd-colorInfo);
  --plasmic-token-system-info: var(--token-CtWQF2P39aLo);
  --token-b54tAmlgUaXe: var(--antd-colorText);
  --plasmic-token-system-text: var(--token-b54tAmlgUaXe);
  --token-qlDkklux5JrT: var(--antd-colorTextSecondary);
  --plasmic-token-system-text-secondary: var(--token-qlDkklux5JrT);
  --token-Unt2O2MnUneb: var(--antd-colorTextTertiary);
  --plasmic-token-system-text-tertiary: var(--token-Unt2O2MnUneb);
  --token-ehVEIAjb9HeO: var(--antd-colorTextQuaternary);
  --plasmic-token-system-text-quaternary: var(--token-ehVEIAjb9HeO);
  --token-pyhDq7s0RTkz: var(--antd-colorBorder);
  --plasmic-token-system-border: var(--token-pyhDq7s0RTkz);
  --token-Xzi5OT07e2_1: var(--antd-colorBorderSecondary);
  --plasmic-token-system-border-secondary: var(--token-Xzi5OT07e2_1);
  --token-iSm9mh5cC952: var(--antd-colorFill);
  --plasmic-token-system-fill: var(--token-iSm9mh5cC952);
  --token-pLZthFInDOSZ: var(--antd-colorFillSecondary);
  --plasmic-token-system-fill-secondary: var(--token-pLZthFInDOSZ);
  --token-PFNQE33ELh3b: var(--antd-colorFillTertiary);
  --plasmic-token-system-fill-tertiary: var(--token-PFNQE33ELh3b);
  --token-MeZ_ciy2nAhO: var(--antd-colorFillQuaternary);
  --plasmic-token-system-fill-quaternary: var(--token-MeZ_ciy2nAhO);
  --token-as44I7WwR-rZ: var(--antd-colorBgLayout);
  --plasmic-token-system-bg-layout: var(--token-as44I7WwR-rZ);
  --token-41VRhDKfihhY: var(--antd-colorBgContainer);
  --plasmic-token-system-bg-container: var(--token-41VRhDKfihhY);
  --token-GGYj13plNx6v: var(--antd-colorBgElevated);
  --plasmic-token-system-bg-elevated: var(--token-GGYj13plNx6v);
  --token-WxvvgatuPYoJ: var(--antd-colorBgSpotlight);
  --plasmic-token-system-bg-spotlight: var(--token-WxvvgatuPYoJ);
  --token-lIvc0WM5-V4N: var(--antd-colorPrimaryBg);
  --plasmic-token-system-primary-bg: var(--token-lIvc0WM5-V4N);
  --token-WxyGMLCV1Pdq: var(--antd-colorPrimaryBgHover);
  --plasmic-token-system-primary-bg-hover: var(--token-WxyGMLCV1Pdq);
  --token-IlR0iqdnKI_m: var(--antd-colorPrimaryBorder);
  --plasmic-token-system-primary-border: var(--token-IlR0iqdnKI_m);
  --token-7yzgT3X0hxHm: var(--antd-colorPrimaryBorderHover);
  --plasmic-token-system-primary-border-hover: var(--token-7yzgT3X0hxHm);
  --token-zSc5dQE2YXMN: var(--antd-colorPrimaryHover);
  --plasmic-token-system-primary-hover: var(--token-zSc5dQE2YXMN);
  --token-5OZEb1MGGCI4: var(--antd-colorPrimaryActive);
  --plasmic-token-system-primary-active: var(--token-5OZEb1MGGCI4);
  --token-7EicNAKGWsev: var(--antd-colorPrimaryTextHover);
  --plasmic-token-system-primary-text-hover: var(--token-7EicNAKGWsev);
  --token-jULyYuL3e-TL: var(--antd-colorPrimaryText);
  --plasmic-token-system-primary-text: var(--token-jULyYuL3e-TL);
  --token-fyoD0poQfxvl: var(--antd-colorPrimaryTextActive);
  --plasmic-token-system-primary-text-active: var(--token-fyoD0poQfxvl);
  --token-oh_zdnnQ9zjm: var(--antd-colorSuccessBg);
  --plasmic-token-system-success-bg: var(--token-oh_zdnnQ9zjm);
  --token-aTHxjKoUeWnv: var(--antd-colorSuccessBgHover);
  --plasmic-token-system-success-bg-hover: var(--token-aTHxjKoUeWnv);
  --token-yY5WOXi2jAzm: var(--antd-colorSuccessBorder);
  --plasmic-token-system-success-border: var(--token-yY5WOXi2jAzm);
  --token-_5gBev-c4IE1: var(--antd-colorSuccessBorderHover);
  --plasmic-token-system-success-border-hover: var(--token-_5gBev-c4IE1);
  --token-EfBA1Z63plMe: var(--antd-colorSuccessHover);
  --plasmic-token-system-success-hover: var(--token-EfBA1Z63plMe);
  --token-Z7tAkk9KA7lq: var(--antd-colorSuccessActive);
  --plasmic-token-system-success-active: var(--token-Z7tAkk9KA7lq);
  --token-N44WQybcxHnK: var(--antd-colorSuccessTextHover);
  --plasmic-token-system-success-text-hover: var(--token-N44WQybcxHnK);
  --token--9SFkh0xiZqQ: var(--antd-colorSuccessText);
  --plasmic-token-system-success-text: var(--token--9SFkh0xiZqQ);
  --token-hrMucCmuVP2a: var(--antd-colorSuccessTextActive);
  --plasmic-token-system-success-text-active: var(--token-hrMucCmuVP2a);
  --token-SXG6cjnWZyNC: var(--antd-colorWarningBg);
  --plasmic-token-system-warning-bg: var(--token-SXG6cjnWZyNC);
  --token-arm2L2XW_xJ6: var(--antd-colorWarningBgHover);
  --plasmic-token-system-warning-bg-hover: var(--token-arm2L2XW_xJ6);
  --token-dZTqA8w80eW1: var(--antd-colorWarningBorder);
  --plasmic-token-system-warning-border: var(--token-dZTqA8w80eW1);
  --token-LCC99oGt2mlX: var(--antd-colorWarningBorderHover);
  --plasmic-token-system-warning-border-hover: var(--token-LCC99oGt2mlX);
  --token-3GX45hKJgVgM: var(--antd-colorWarningHover);
  --plasmic-token-system-warning-hover: var(--token-3GX45hKJgVgM);
  --token-Bjjq_24AWhes: var(--antd-colorWarningActive);
  --plasmic-token-system-warning-active: var(--token-Bjjq_24AWhes);
  --token-bASdKwkcGC-_: var(--antd-colorWarningTextHover);
  --plasmic-token-system-warning-text-hover: var(--token-bASdKwkcGC-_);
  --token-LqD8W4lcEdIj: var(--antd-colorWarningText);
  --plasmic-token-system-warning-text: var(--token-LqD8W4lcEdIj);
  --token-MAnhFXGh8Ir8: var(--antd-colorWarningTextActive);
  --plasmic-token-system-warning-text-active: var(--token-MAnhFXGh8Ir8);
  --token-oDTVzkxdJ872: var(--antd-colorInfoBg);
  --plasmic-token-system-info-bg: var(--token-oDTVzkxdJ872);
  --token-g_VDjIuHq0U5: var(--antd-colorInfoBgHover);
  --plasmic-token-system-info-bg-hover: var(--token-g_VDjIuHq0U5);
  --token-mQuOloHwx25-: var(--antd-colorInfoBorder);
  --plasmic-token-system-info-border: var(--token-mQuOloHwx25-);
  --token-ytBWtTwx11rx: var(--antd-colorInfoBorderHover);
  --plasmic-token-system-info-border-hover: var(--token-ytBWtTwx11rx);
  --token-ReYtjy5W-zM7: var(--antd-colorInfoHover);
  --plasmic-token-system-info-hover: var(--token-ReYtjy5W-zM7);
  --token-2xPkbw4aFzX2: var(--antd-colorInfoActive);
  --plasmic-token-system-info-active: var(--token-2xPkbw4aFzX2);
  --token-bywOirYXcruc: var(--antd-colorInfoTextHover);
  --plasmic-token-system-info-text-hover: var(--token-bywOirYXcruc);
  --token-uKRz3ov0rqIf: var(--antd-colorInfoText);
  --plasmic-token-system-info-text: var(--token-uKRz3ov0rqIf);
  --token-ADrp2mfNmuLA: var(--antd-colorInfoTextActive);
  --plasmic-token-system-info-text-active: var(--token-ADrp2mfNmuLA);
  --token-4ccrUZ-M3zhx: var(--antd-colorErrorBg);
  --plasmic-token-system-error-bg: var(--token-4ccrUZ-M3zhx);
  --token-p9OxE4l9Com5: var(--antd-colorErrorBgHover);
  --plasmic-token-system-error-bg-hover: var(--token-p9OxE4l9Com5);
  --token-P02GAf-HlHok: var(--antd-colorErrorBorder);
  --plasmic-token-system-error-border: var(--token-P02GAf-HlHok);
  --token-0gAjYifG8xWY: var(--antd-colorErrorBorderHover);
  --plasmic-token-system-error-border-hover: var(--token-0gAjYifG8xWY);
  --token-_u6T-ctQmwmL: var(--antd-colorErrorHover);
  --plasmic-token-system-error-hover: var(--token-_u6T-ctQmwmL);
  --token-j-ZwOKbpUCqd: var(--antd-colorErrorActive);
  --plasmic-token-system-error-active: var(--token-j-ZwOKbpUCqd);
  --token-V6MqwrLdbww7: var(--antd-colorErrorTextHover);
  --plasmic-token-system-error-text-hover: var(--token-V6MqwrLdbww7);
  --token-ddpevpfGL4QK: var(--antd-colorErrorText);
  --plasmic-token-system-error-text: var(--token-ddpevpfGL4QK);
  --token-HxnmpURJ0E1r: var(--antd-colorErrorTextActive);
  --plasmic-token-system-error-text-active: var(--token-HxnmpURJ0E1r);
  --token-O-63NKRcNhLJ: var(--antd-colorWhite);
  --plasmic-token-system-white: var(--token-O-63NKRcNhLJ);
  --token-2aHIw8xI62Xi: var(--antd-colorBgMask);
  --plasmic-token-system-bg-mask: var(--token-2aHIw8xI62Xi);
  --token-TpmbTrq5hCAj: var(--antd-colorIcon);
  --plasmic-token-system-icon: var(--token-TpmbTrq5hCAj);
  --token-hb610yGAurc2: var(--antd-colorIconHover);
  --plasmic-token-system-icon-hover: var(--token-hb610yGAurc2);
  --token-lLkc5xWMZR34: var(--antd-colorLink);
  --plasmic-token-system-link: var(--token-lLkc5xWMZR34);
  --token-wSnKEwu5y_PF: var(--antd-colorLinkHover);
  --plasmic-token-system-link-hover: var(--token-wSnKEwu5y_PF);
  --token-o9SjJ7GuRCZJ: var(--antd-paddingXXS);
  --plasmic-token-system-padding-xxs: var(--token-o9SjJ7GuRCZJ);
  --token-MlCXSE1p_B5w: var(--antd-paddingXS);
  --plasmic-token-system-padding-xs: var(--token-MlCXSE1p_B5w);
  --token-o3y7S9gx0ndk: var(--antd-paddingSM);
  --plasmic-token-system-padding-sm: var(--token-o3y7S9gx0ndk);
  --token-GnePloX-BYUf: var(--antd-padding);
  --plasmic-token-system-padding-m: var(--token-GnePloX-BYUf);
  --token-pgaudsfqNIH0: var(--antd-paddingMD);
  --plasmic-token-system-padding-md: var(--token-pgaudsfqNIH0);
  --token-hfUqRMNdS5wL: var(--antd-paddingLG);
  --plasmic-token-system-padding-lg: var(--token-hfUqRMNdS5wL);
  --token-0aJ0ExCGAHaV: var(--antd-paddingXL);
  --plasmic-token-system-padding-xl: var(--token-0aJ0ExCGAHaV);
  --token-fCBRzfPOBNOL: var(--antd-marginXXS);
  --plasmic-token-system-margin-xxs: var(--token-fCBRzfPOBNOL);
  --token-dT2WOkFV5Mee: var(--antd-marginXS);
  --plasmic-token-system-margin-xs: var(--token-dT2WOkFV5Mee);
  --token-5qBYYrKVDrlB: var(--antd-marginSM);
  --plasmic-token-system-margin-sm: var(--token-5qBYYrKVDrlB);
  --token-rX_8jNE36opk: var(--antd-margin);
  --plasmic-token-system-margin-m: var(--token-rX_8jNE36opk);
  --token-Gtd-oD4SfydH: var(--antd-marginMD);
  --plasmic-token-system-margin-md: var(--token-Gtd-oD4SfydH);
  --token-sPi98nT-JyUw: var(--antd-marginLG);
  --plasmic-token-system-margin-lg: var(--token-sPi98nT-JyUw);
  --token-PUpYMghDbUAb: var(--antd-marginXL);
  --plasmic-token-system-margin-xl: var(--token-PUpYMghDbUAb);
  --token-3li6WPFiJWAX: var(--antd-marginXXL);
  --plasmic-token-system-margin-xxl: var(--token-3li6WPFiJWAX);
  --token-fwZvoB_ZCfwn: var(--antd-fontSize);
  --plasmic-token-system-m: var(--token-fwZvoB_ZCfwn);
  --token-S57U-ojSMftk: var(--antd-fontSizeSM);
  --plasmic-token-system-sm: var(--token-S57U-ojSMftk);
  --token-LGg-zkf2M2GB: var(--antd-fontSizeLG);
  --plasmic-token-system-lg: var(--token-LGg-zkf2M2GB);
  --token-H1BaJ8q_hk3H: var(--antd-fontSizeXL);
  --plasmic-token-system-xl: var(--token-H1BaJ8q_hk3H);
  --token-IvcMj9ZyTMo8: var(--antd-fontSizeHeading1);
  --plasmic-token-system-heading-1: var(--token-IvcMj9ZyTMo8);
  --token-KlrAoGIT1za7: var(--antd-fontSizeHeading2);
  --plasmic-token-system-heading-2: var(--token-KlrAoGIT1za7);
  --token-16_cC9w3e0Nk: var(--antd-fontSizeHeading3);
  --plasmic-token-system-heading-3: var(--token-16_cC9w3e0Nk);
  --token-hCgNygAysIbb: var(--antd-fontSizeHeading4);
  --plasmic-token-system-heading-4: var(--token-hCgNygAysIbb);
  --token-70Yb0tqelyc1: var(--antd-fontSizeHeading5);
  --plasmic-token-system-heading-5: var(--token-70Yb0tqelyc1);
  --token-snVsVaM8n5_4: var(--antd-lineHeight);
  --plasmic-token-system-m: var(--token-snVsVaM8n5_4);
  --token-jb8CFgMUZa8u: var(--antd-lineHeightLG);
  --plasmic-token-system-lg: var(--token-jb8CFgMUZa8u);
  --token-SVc6Y_f02A4U: var(--antd-lineHeightSM);
  --plasmic-token-system-sm: var(--token-SVc6Y_f02A4U);
  --token-xwvpf2CK6_-R: var(--antd-lineHeightHeading1);
  --plasmic-token-system-heading-1: var(--token-xwvpf2CK6_-R);
  --token-bJEU6Bg7WMDd: var(--antd-lineHeightHeading2);
  --plasmic-token-system-heading-2: var(--token-bJEU6Bg7WMDd);
  --token-9aYtHZjcigNn: var(--antd-lineHeightHeading3);
  --plasmic-token-system-heading-3: var(--token-9aYtHZjcigNn);
  --token-5UxZgePJMjDS: var(--antd-lineHeightHeading4);
  --plasmic-token-system-heading-4: var(--token-5UxZgePJMjDS);
  --token-NIHokAJdLa88: var(--antd-lineHeightHeading5);
  --plasmic-token-system-heading-5: var(--token-NIHokAJdLa88);
}

:where(.plasmic_plasmic_rich_components_all__HR_OY) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.plasmic_plasmic_rich_components_img___bvmN) {
  display: inline-block;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H img) {
  white-space: inherit;
}

:where(.plasmic_plasmic_rich_components_li__F8QKI) {
  display: list-item;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H li) {
  white-space: inherit;
}

:where(.plasmic_plasmic_rich_components_span__NkX7N) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_plasmic_rich_components_input__HiaPd) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_plasmic_rich_components_textarea__7o_Rz) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_plasmic_rich_components_button__F0Zjq) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.plasmic_plasmic_rich_components_code__EzqSS) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_plasmic_rich_components_pre__pLSov) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_plasmic_rich_components_p__x4_eS) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_plasmic_rich_components_h1__8gsz4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_plasmic_rich_components_h2__ZKgHe) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_plasmic_rich_components_h3__A5uE8) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_plasmic_rich_components_h4__e8fcF) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_plasmic_rich_components_h5__UTaHS) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_plasmic_rich_components_h6__a_sc0) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_plasmic_rich_components_address__MEO0Z) {
  font-style: inherit;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.plasmic_plasmic_rich_components_a__RY7qW) {
  color: inherit;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H a) {
  white-space: inherit;
  color: inherit;
}

:where(.plasmic_plasmic_rich_components_ol__qWrQu) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_plasmic_rich_components_ul__ELtxv) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_plasmic_rich_components_select__twww7) {
  padding: 2px 6px;
}
:where(.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_plasmic_rich_components_plasmic_default__component_wrapper__pTyVT {
  display: grid;
}
.plasmic_plasmic_rich_components_plasmic_default__inline__qM9oc {
  display: inline;
}
.plasmic_plasmic_rich_components_plasmic_page_wrapper__jlXo_ {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_plasmic_rich_components_plasmic_page_wrapper__jlXo_ > * {
  height: auto !important;
}
.plasmic_plasmic_rich_components___wab_expr_html_text__t08_H {
  white-space: normal;
}
:where(.plasmic_plasmic_rich_components_root_reset__tOoqb) {
}

